<template>
	<div>
	
		<div class="content content_top_margin">
			<div class="content_inner  ">
				<div class="full_width ">
					<div class="full_width_inner">

						<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<div class="vc_empty_space" style="height: 128px"><span
														class="vc_empty_space_inner">
														<span class="empty_space_image"></span>
													</span></div>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="d-flex align-items-start">
							<div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
								<button class="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Home</button>
								<button class="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Profile</button>
								<button class="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Messages</button>
								<button class="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Settings</button>
							</div>
							<div class="tab-content" id="v-pills-tabContent">
								<div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">...</div>
								<div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">...</div>
								<div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">...</div>
								<div class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">...</div>
							</div>
						</div> -->
						<div class="vc_row wpb_row section vc_row-fluid  grid_section" style=' text-align:left;'>
							<div class=" section_inner clearfix">
								<div class='section_inner_margin clearfix'>
									<div class="wpb_column vc_column_container vc_col-sm-12">
										<div class="vc_column-inner">
											<div class="wpb_wrapper">
												<section class="elegant-tabs-container">
													<!-- <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
														<button class="nav-link active" id="section-1490849652533-3-7-tab" data-bs-toggle="pill" data-bs-target="#section-1490849652533-3-7" type="button" role="tab" aria-controls="section-1490849652533-3-7" aria-selected="true">Home</button>
														<button class="nav-link" id="section-video-tab" data-bs-toggle="pill" data-bs-target="#section-video" type="button" role="tab" aria-controls="section-video" aria-selected="false">Profile</button>
														<button class="nav-link" id="section-1491247171289-6-8-tab" data-bs-toggle="pill" data-bs-target="#section-1491247171289-6-8" type="button" role="tab" aria-controls="section-1491247171289-6-8" aria-selected="false">Messages</button>
														<button class="nav-link" id="section-1490849240792-0-tab" data-bs-toggle="pill" data-bs-target="#section-1490849240792-0" type="button" role="tab" aria-controls="section-1490849240792-0" aria-selected="false">Settings</button>
													</div> -->
													<div class="et-tabs et-vertical et-tabs-style-bars video-tabs tab-class-113309813 et-align-right customcode"
														data-tab_style="bars video-tabs" data-active-bg="#f7f7f7"
														data-active-text="#326cf4" >
														<nav class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
															
																	<button class="nav-link active" id="section-1490849652533-3-7-tab" data-bs-toggle="pill" data-bs-target="#section-1490849652533-3-7" type="button" role="tab" aria-controls="section-1490849652533-3-7" aria-selected="true">Sit / Stand Tips</button>
																
																	<button class="nav-link" id="section-video-tab" data-bs-toggle="pill" data-bs-target="#section-video" type="button" role="tab" aria-controls="section-video" aria-selected="false">yping Do's &amp;
																			Don'ts</button>
																
																	<button class="nav-link" id="section-1491247171289-6-8-tab" data-bs-toggle="pill" data-bs-target="#section-1491247171289-6-8" type="button" role="tab" aria-controls="section-1491247171289-6-8" aria-selected="false">Mousing Do's &amp;
																			Don'ts</button>
															
																	<button class="nav-link" id="section-1490849240792-0-tab" data-bs-toggle="pill" data-bs-target="#section-1490849240792-0" type="button" role="tab" aria-controls="section-1490849240792-0" aria-selected="false">Law &amp; Ergo</button>
															
														</nav> 
														
														<div class="et-content-wrap" style="width:75%">
														
															<section id="section-1490849652533-3-7" class="tab-pane fade show active" role="tabpanel" aria-labelledby="section-1490849652533-3-7-tab"
																style="background:; color:#565656;">
																<div class="infi-content-wrapper">
																	<div class="vc_row wpb_row section vc_row-fluid vc_inner "
																		style=' text-align:left;'>
																		<div class=" full_section_inner clearfix">
																			<div
																				class="wpb_column vc_column_container vc_col-sm-12">
																				<div class="vc_column-inner">
																					<div class="wpb_wrapper">
																						<div
																							class="wpb_text_column wpb_content_element  vc_custom_1491767376284">
																							<div class="wpb_wrapper">
																								<h4><span
																										style="color: #9e9e9e;">ERGO
																										ESSENTIALS /
																									</span> <span
																										style="color: #007a87">SIT
																										/ STAND
																										TIPS</span></h4>

																							</div>
																						</div>
																						<div class="vc_empty_space"
																							style="height: 22px"><span
																								class="vc_empty_space_inner">
																								<span
																									class="empty_space_image"></span>
																							</span></div>


																						<div
																							class="wpb_video_widget wpb_content_element vc_clearfix   vc_custom_1491857900120 vc_video-aspect-ratio-169 vc_video-el-width-100 vc_video-align-left">
																							<div class="wpb_wrapper">

																								<div
																									class="wpb_video_wrapper">
																									<iframe
																										src="https://player.vimeo.com/video/139042441?dnt=1&amp;app_id=122963"
																										width="1060"
																										height="596"
																										frameborder="0"
																										allow="autoplay; fullscreen; picture-in-picture"
																										allowfullscreen></iframe>
																								</div>
																							</div>
																						</div>
																						<div class="vc_empty_space"
																							style="height: 22px"><span
																								class="vc_empty_space_inner">
																								<span
																									class="empty_space_image"></span>
																							</span></div>


																						<div
																							class="wpb_text_column wpb_content_element  vc_custom_1491767818470">
																							<div class="wpb_wrapper">
																								<h4><span
																										style="color: #007a87">Sit
																										/ Stand Tips
																									</span> <img
																										loading="lazy"
																										class="alignnone size-full wp-image-23828"
																										src="../../assets/images/airbnb/icon-clock-1.png"
																										alt=""
																										width="14"
																										height="14" />
																									2:45</h4>
																								<p>If you use a
																									sit/stand desk, this
																									video will help you
																									maximize your
																									comfort, sit and
																									stand with awesome
																									posture and help you
																									stay energized
																									throughout the day.
																								</p>

																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</section>
															
															<section id="section-video" class="tab-pane fade" role="tabpanel" aria-labelledby="section-video-tab"
																style="background:; color:#565656;">
																<div class="infi-content-wrapper">
																	<div class="vc_row wpb_row section vc_row-fluid vc_inner "
																		style=' text-align:left;'>
																		<div class=" full_section_inner clearfix">
																			<div
																				class="wpb_column vc_column_container vc_col-sm-12">
																				<div class="vc_column-inner">
																					<div class="wpb_wrapper">
																						<div
																							class="wpb_text_column wpb_content_element  vc_custom_1491767436875">
																							<div class="wpb_wrapper">
																								<h4><span
																										style="color: #9e9e9e;">ERGO
																										ESSENTIALS /
																									</span> <span
																										style="color: #007a87">TYPING
																										DO&#8217;S &amp;
																										DON&#8217;TS</span>
																								</h4>

																							</div>
																						</div>
																						<div class="vc_empty_space"
																							style="height: 22px"><span
																								class="vc_empty_space_inner">
																								<span
																									class="empty_space_image"></span>
																							</span></div>


																						<div
																							class="wpb_video_widget wpb_content_element vc_clearfix   vc_custom_1491767829491 vc_video-aspect-ratio-169 vc_video-el-width-100 vc_video-align-left">
																							<div class="wpb_wrapper">

																								<div
																									class="wpb_video_wrapper">
																									<iframe
																										src="https://player.vimeo.com/video/140463337?dnt=1&amp;app_id=122963"
																										width="1060"
																										height="596"
																										frameborder="0"
																										allow="autoplay; fullscreen; picture-in-picture"
																										allowfullscreen></iframe>
																								</div>
																							</div>
																						</div>
																						<div class="vc_empty_space"
																							style="height: 22px"><span
																								class="vc_empty_space_inner">
																								<span
																									class="empty_space_image"></span>
																							</span></div>


																						<div
																							class="wpb_text_column wpb_content_element  vc_custom_1491767837783">
																							<div class="wpb_wrapper">
																								<h4><span
																										style="color: #007a87">Typing
																										Do&#8217;s &amp;
																										Don&#8217;ts
																									</span> <img
																										loading="lazy"
																										class="alignnone size-full wp-image-23828"
																										src="../../assets/images/airbnb/icon-clock-1.png"
																										alt=""
																										width="14"
																										height="14" />
																									0:56</h4>
																								<p>Typing should never
																									feel hard or
																									uncomfortable. Check
																									out this common
																									typing trap so you
																									can avoid falling
																									into it!.</p>

																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</section>
														
															<section id="section-1491247171289-6-8" class="tab-pane fade" role="tabpanel" aria-labelledby="section-1491247171289-6-8-tab"
																style="background:; color:#565656;">
																<div class="infi-content-wrapper">
																	<div class="vc_row wpb_row section vc_row-fluid vc_inner "
																		style=' text-align:left;'>
																		<div class=" full_section_inner clearfix">
																			<div
																				class="wpb_column vc_column_container vc_col-sm-12">
																				<div class="vc_column-inner">
																					<div class="wpb_wrapper">
																						<div
																							class="wpb_text_column wpb_content_element  vc_custom_1491767484160">
																							<div class="wpb_wrapper">
																								<h4><span
																										style="color: #9e9e9e;">ERGO
																										ESSENTIALS /
																									</span> <span
																										style="color: #007a87">MOUSING
																										DO&#8217;S &amp;
																										DON&#8217;TS</span>
																								</h4>

																							</div>
																						</div>
																						<div class="vc_empty_space"
																							style="height: 22px"><span
																								class="vc_empty_space_inner">
																								<span
																									class="empty_space_image"></span>
																							</span></div>


																						<div
																							class="wpb_video_widget wpb_content_element vc_clearfix   vc_custom_1491767847414 vc_video-aspect-ratio-169 vc_video-el-width-100 vc_video-align-left">
																							<div class="wpb_wrapper">

																								<div
																									class="wpb_video_wrapper">
																									<iframe
																										src="https://player.vimeo.com/video/211511218?dnt=1&amp;app_id=122963"
																										width="1060"
																										height="596"
																										frameborder="0"
																										allow="autoplay; fullscreen; picture-in-picture"
																										allowfullscreen></iframe>
																								</div>
																							</div>
																						</div>
																						<div class="vc_empty_space"
																							style="height: 22px"><span
																								class="vc_empty_space_inner">
																								<span
																									class="empty_space_image"></span>
																							</span></div>


																						<div
																							class="wpb_text_column wpb_content_element  vc_custom_1491767856112">
																							<div class="wpb_wrapper">
																								<h4><span
																										style="color: #007a87">Mousing
																										Do&#8217;s &amp;
																										Don&#8217;ts
																									</span> <img
																										loading="lazy"
																										class="alignnone size-full wp-image-23828"
																										src="../../assets/images/airbnb/icon-clock-1.png"
																										alt=""
																										width="14"
																										height="14" />
																									0:56</h4>
																								<p>When you mouse, you
																									should never feel
																									strain or pain. This
																									video will show you
																									how mouse in
																									comfort!</p>

																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</section>
														
															<section id="section-1490849240792-0" class="tab-pane fade" role="tabpanel" aria-labelledby="section-1490849240792-0-tab"
																style="background:; color:#565656;">
																<div class="infi-content-wrapper">
																	<div
																		class="wpb_text_column wpb_content_element  vc_custom_1491767543088">
																		<div class="wpb_wrapper">
																			<h4><span style="color: #9e9e9e;">ERGO
																					ESSENTIALS / </span> <span
																					style="color: #007a87">LAW &amp;
																					ERGO </span></h4>

																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 22px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_video_widget wpb_content_element vc_clearfix   vc_custom_1491767902362 vc_video-aspect-ratio-169 vc_video-el-width-100 vc_video-align-left">
																		<div class="wpb_wrapper">

																			<div class="wpb_video_wrapper"><iframe
																					src="https://player.vimeo.com/video/211507743?dnt=1&amp;app_id=122963"
																					width="1060" height="596"
																					frameborder="0"
																					allow="autoplay; fullscreen; picture-in-picture"
																					allowfullscreen></iframe></div>
																		</div>
																	</div>
																	<div class="vc_empty_space" style="height: 22px">
																		<span class="vc_empty_space_inner">
																			<span class="empty_space_image"></span>
																		</span></div>


																	<div
																		class="wpb_text_column wpb_content_element  vc_custom_1491767911349">
																		<div class="wpb_wrapper">
																			<h4><span style="color: #007a87">Law &amp;
																					Ergo <img loading="lazy"
																						class="alignnone size-full wp-image-23828"
																						src="../../assets/images/airbnb/icon-clock-1.png"
																						alt="" width="14" height="14" />
																				</span>3:48</h4>
																			<p>Good ergo is more than a good set up.
																				It&#8217;s also about how you use your
																				body when working. This hilarious video
																				will show you how!</p>

																		</div>
																	</div>
																</div>
															</section>
														</div> <!-- /et-content-wrap -->
													</div> <!-- /et-tabs -->
												</section>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="vc_row wpb_row section vc_row-fluid " style=' text-align:left;'>
							<div class=" full_section_inner clearfix">
								<div class="wpb_column vc_column_container vc_col-sm-12">
									<div class="vc_column-inner">
										<div class="wpb_wrapper">
											<div class="vc_empty_space" style="height: 32px"><span
													class="vc_empty_space_inner">
													<span class="empty_space_image"></span>
												</span></div>

										</div>
									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
				

			</div>
		</div>
	</div>
</template>

<script>

	//import carousel from "vue-owl-carousel"
	export default {
		name: 'ergo-videos',
	}
</script>